// Definition of action types

// app configuration
export const CONFIG_SET_API_LOGGING = 'CONFIG_SET_API_LOGGING';
export const CONFIG_SET_LANGUAGE = 'CONFIG_SET_LANGUAGE';

// home view (activities)
export const ACTIVITY_ERROR = 'ACTIVITY_ERROR';
export const ACTIVITY_GOT_ADMIN = 'ACTIVITY_GOT_ADMIN';
export const ACTIVITY_GOT_ALL = 'ACTIVITY_GOT_ALL';
export const ACTIVITY_GOT_OWN = 'ACTIVITY_GOT_OWN';

// login/logout
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_USER = 'AUTH_USER';

// club view
export const CLUB_CREATED = 'CLUB_CREATED';
export const CLUB_DELETED = 'CLUB_DELETED';
export const CLUB_ERROR = 'CLUB_ERROR';
export const CLUB_GOT_LIST = 'CLUB_GOT_LIST';
export const CLUB_UPDATED = 'CLUB_UPDATED';

export const CLUB_CHANGE_SEARCH_FIELD = 'CLUB_CHANGE_SEARCH_FIELD';
export const CLUB_CHANGE_VIEW_MODE = 'CLUB_CHANGE_VIEW_MODE';
export const CLUB_SELECT_CLUB = 'CLUB_SELECT_CLUB';
export const CLUB_SELECT_CLUB_EVENT = 'CLUB_SELECT_CLUB_EVENT';
export const CLUB_SELECT_CLUB_MEMBER = 'CLUB_SELECT_CLUB_MEMBER';

// user view and logged in user details
export const USER_CHANGED_PASSWORD = 'USER_CHANGED_PASSWORD';
export const USER_DELETED = 'USER_DELETED';
export const USER_DELETED_IMAGE = 'USER_DELETED_IMAGE';
export const USER_ERROR = 'USER_ERROR';
export const USER_GOT_BY_ID = 'USER_GOT_BY_ID';
export const USER_GOT_CURRENT = 'USER_GOT_CURRENT';
export const USER_GOT_LIST = 'USER_GOT_LIST';
export const USER_POSTED_IMAGE = 'USER_POSTED_IMAGE';
export const USER_UPDATED = 'USER_UPDATED';

export const USER_CHANGE_SEARCH_FIELD = 'USER_CHANGE_SEARCH_FIELD';
export const USER_CHANGE_VIEW_MODE = 'USER_CHANGE_VIEW_MODE';
export const USER_CHANGE_VIEW_MODE_SELF = 'USER_CHANGE_VIEW_MODE_SELF';
export const USER_SELECT_USER = 'USER_SELECT_USER';

// events view
export const EVENT_COMMENT_ADDED = 'EVENT_COMMENT_ADDED';
export const EVENT_COMMENT_DELETED = 'EVENT_COMMENT_DELETED';
export const EVENT_COMMENT_UPDATED = 'EVENT_COMMENT_UPDATED';
export const EVENT_CREATED = 'EVENT_CREATED'; // same action whether or not ORIS ref is used
export const EVENT_DELETED = 'EVENT_DELETED';
export const EVENT_ERROR = 'EVENT_ERROR';
export const EVENT_GOT_BY_ID = 'EVENT_GOT_BY_ID';
export const EVENT_GOT_EVENT_LINK_LIST = 'EVENT_GOT_EVENT_LINK_LIST';
export const EVENT_GOT_LIST = 'EVENT_GOT_LIST';
export const EVENT_GOT_ORIS_LIST = 'EVENT_GOT_ORIS_LIST';
export const EVENT_LINK_CREATED = 'EVENT_LINK_CREATED';
export const EVENT_LINK_DELETED = 'EVENT_LINK_DELETED';
export const EVENT_LINK_UPDATED = 'EVENT_LINK_UPDATED';
export const EVENT_MAP_DELETED = 'EVENT_MAP_DELETED';
export const EVENT_MAP_UPLOADED = 'EVENT_MAP_UPLOADED';
export const EVENT_RUNNER_ADDED = 'EVENT_RUNNER_ADDED';
export const EVENT_RUNNER_DELETED = 'EVENT_RUNNER_DELETED';
export const EVENT_RUNNER_UPDATED = 'EVENT_RUNNER_UPDATED';
export const EVENT_UPDATED = 'EVENT_UPDATED';

export const EVENT_CHANGE_SEARCH_FIELD_EVENTS = 'EVENT_CHANGE_SEARCH_FIELD_EVENTS';
export const EVENT_CHANGE_SEARCH_FIELD_MYMAPS = 'EVENT_CHANGE_SEARCH_FIELD_MYMAPS';
export const EVENT_CHANGE_TAG_FILTER_EVENTS = 'EVENT_CHANGE_TAG_FILTER_EVENTS';
export const EVENT_CHANGE_TAG_FILTER_MYMAPS = 'EVENT_CHANGE_TAG_FILTER_MYMAPS';
export const EVENT_CHANGE_VIEW_EVENT_EVENTS = 'EVENT_CHANGE_VIEW_EVENT_EVENTS';
export const EVENT_CHANGE_VIEW_EVENT_LINK = 'EVENT_CHANGE_VIEW_EVENT_LINK';
export const EVENT_CHANGE_VIEW_EVENT_MAPVIEW = 'EVENT_CHANGE_VIEW_EVENT_MAPVIEW';
export const EVENT_CHANGE_VIEW_EVENT_MYMAPS = 'EVENT_CHANGE_VIEW_EVENT_MYMAPS';
export const EVENT_CHANGE_VIEW_RUNNER = 'EVENT_CHANGE_VIEW_RUNNER';
export const EVENT_MAP_SET_BOUNDS_EVENTS = 'EVENT_MAP_SET_BOUNDS_EVENTS';
export const EVENT_MAP_SET_BOUNDS_MYMAPS = 'EVENT_MAP_SET_BOUNDS_MYMAPS';
export const EVENT_SELECT_EVENT_ID_EVENTS = 'EVENT_SELECT_EVENT_ID_EVENTS';
export const EVENT_SELECT_EVENT_ID_MAPVIEW = 'EVENT_SELECT_EVENT_ID_MAPVIEW';
export const EVENT_SELECT_EVENT_ID_MYMAPS = 'EVENT_SELECT_EVENT_ID_MYMAPS';
export const EVENT_SELECT_MAP = 'EVENT_SELECT_MAP';
export const EVENT_SELECT_RUNNER = 'EVENT_SELECT_RUNNER';
export const EVENT_SET_MAP_VIEW_PARAMETERS = 'EVENT_SET_MAP_VIEW_PARAMETERS';
